<template>
  <section id="intro" class="py-16">
    <v-container>
      <v-responsive class="max-auto mx-auto text-center" max-width="600">
        <v-avatar color="primary" size="70" class="mb-8">
          <v-icon x-large dark>mdi-calendar-clock</v-icon>
        </v-avatar>
        <h2 class="text-h4 text-md-h3 text-center font-weight-black mb-7">
          Aportando en la Coordinación de Reuniones
        </h2>
        <p class="title font-weight-light">
          Synedria — una plataforma web que permite coordinar reuniones de manera rápida y sencilla mediante el uso de calendarios compartidos.
        </p>
      </v-responsive>

      <v-row class="pt-12">
        <v-col v-for="feature in features" :key="feature.title" cols="12" md="6">
          <v-row no-gutters>
            <v-col :cols="feature.callout ? 9 : 12">
              <div class="pr-2">
                <div class="text--disabled" v-text="feature.subtitle"></div>
                <h4
                  class="text-uppercase mt-1 mb-4"
                  style="letter-spacing: 0.15em"
                  v-text="feature.title"
                ></h4>
                <p v-text="feature.text"></p>
              </div>
            </v-col>
            <v-col v-if="feature.callout" cols="2">
              <span
                class="text-h3 grey--text font-weight-bold pr-8"
                style="opacity: 0.1"
                >{{ feature.callout }}</span
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          title: 'Calendario Actualizado',
          subtitle: 'Organización Perfecta',
          text: 'Mantén tu calendario de eventos siempre actualizado para organizar reuniones y encuentros de manera efectiva.',
          callout: '01',
        },
        {
          title: 'Diseño Intuitivo',
          subtitle: 'Fácil de Usar',
          text: 'Disfruta de un sistema intuitivo, accesible para usuarios con diferentes niveles de habilidad técnica.',
          callout: '02',
        },
        {
          title: 'Gestión de Actividades',
          subtitle: 'Sin Conflictos',
          text: 'Gestiona tus actividades sin preocupaciones de solapamientos de horarios con nuestra herramienta optimizada.',
          callout: '04',
        },
        {
          title: 'Programación de Eventos',
          subtitle: 'Coordinación Eficiente',
          text: 'Organiza y coordina tus eventos con facilidad gracias a nuestros componentes de programación avanzada.',
          callout: '03',
        },
      ],
    }
  },
}
</script>
