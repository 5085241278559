<template>
  <section id="hero">
    <v-carousel height="calc(100vh - 64px)" dark cycle show-arrows-on-hover draggable="true" hide-delimiter-background>
      <v-carousel-item v-for="(carousel, carindex) in carouselsData" :key="carindex" :src="carousel.src"
        class="gradient-fill">
        <v-container fill-height>
          <div style="max-width: 600px">
            <div class="text-md-h2 mb-3 text-sm-h3 text-h5 font-weight-black white--text">
              {{ carousel.heading.toUpperCase() }}
            </div>
            <p class="mb-5 white--text">{{ carousel.subHeading }}</p>
           <RouterLink to="/registro"><v-btn :x-large="$vuetify.breakpoint.smAndUp" class="my-3 primary">Regístrate</v-btn></RouterLink> 
            <span class="mx-2 my-4"></span>
            <RouterLink to="/about"> <v-btn :x-large="$vuetify.breakpoint.smAndUp" text class="my-3" outlined dark><v-icon left large
                color="primary">mdi-play</v-icon>Leer Más</v-btn></RouterLink> 
          </div>
        </v-container>
      </v-carousel-item>
    </v-carousel>
  </section>
</template>

<script>
import { RouterLink } from 'vue-router';
export default {
  components: { RouterLink },
  data() {
    return {
      carouselsData: [
        {
          src: 'https://thumbs.dreamstime.com/b/fondo-de-color-azul-pastel-degradado-y-turquesa-abstracto-con-espacio-copia-clara-en-el-centro-para-dise%C3%B1o-254573548.jpg',
          heading: ' Synedria ',
          subHeading:
            'Descubre el futuro de la coordinación de reuniones con nuestro innovador sistema, diseñado para simplificar y mejorar la experiencia de organización de eventos y encuentros. ',
        },
        {
          src: 'https://thumbs.dreamstime.com/b/fondo-de-color-azul-pastel-degradado-y-turquesa-abstracto-con-espacio-copia-clara-en-el-centro-para-dise%C3%B1o-254573548.jpg',
          heading: ' Synedria ',
          subHeading:
            'Descubre el futuro de la coordinación de reuniones con nuestro innovador sistema, diseñado para simplificar y mejorar la experiencia de organización de eventos y encuentros. ',
        },
        {
          src: 'https://thumbs.dreamstime.com/b/fondo-de-color-azul-pastel-degradado-y-turquesa-abstracto-con-espacio-copia-clara-en-el-centro-para-dise%C3%B1o-254573548.jpg',
          heading: ' Synedria ',
          subHeading:
            'Descubre el futuro de la coordinación de reuniones con nuestro innovador sistema, diseñado para simplificar y mejorar la experiencia de organización de eventos y encuentros. ',
        },
        {
          src: 'https://thumbs.dreamstime.com/b/fondo-de-color-azul-pastel-degradado-y-turquesa-abstracto-con-espacio-copia-clara-en-el-centro-para-dise%C3%B1o-254573548.jpg',
          heading: ' Synedria ',
          subHeading:
            'Descubre el futuro de la coordinación de reuniones con nuestro innovador sistema, diseñado para simplificar y mejorar la experiencia de organización de eventos y encuentros. ',
        },
      ],
    }
  },
}
</script>

<style>
.gradient-fill .v-responsive__content {
  background: rgb(0, 0, 0);
  background: linear-gradient(to right,
      rgba(3, 12, 41, 0.75),
      rgba(5, 11, 31, 0.65));
}
</style>
