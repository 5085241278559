<template>
  <div>
    <v-navigation-drawer v-model="drawer" fixed app temporary>
      <v-list dense>
        <v-list-item-group v-for="(item, i) in items" :key="i" color="primary">
          <v-list-item v-if="!item.submenu" :to="item.to">
            <v-list-item-action>
              <!-- <v-icon>{{ item.icon }}</v-icon> -->
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title.toUpperCase()" />
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title.toUpperCase()"></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="child in item.submenu" :key="child.title" :to="child.to">
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar fixed app hide-on-scroll height="64" elevate-on-scroll>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = true" />
      <router-link to="/" class="d-flex">
        <img src="https://i.postimg.cc/SsNhL0nc/logo3.png" alt="" style="height: 50px;">
      </router-link>
      <v-spacer />

      <template v-for="(name, menuitem) in items">
        <template v-if="name.submenu">
          <v-menu :key="menuitem" open-on-hover offset-y transition="slide-y-transition" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn plain class="py-5 submenubtn hidden-sm-and-down" :ripple="false" v-bind="attrs" style="height: auto"
                v-on="on">
                {{ name.title }}
                <v-icon right small class="mx-0"> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, index) in name.submenu" :key="index" link :to="item.to">
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <v-btn v-else :key="menuitem" depressed tile plain class="py-8 hidden-sm-and-down" :to="name.to">{{ name.title
        }}</v-btn> </template><v-spacer />

      <!-- <v-btn icon @click="changeThemeColor">
        <v-icon>{{
          $vuetify.theme.dark ? 'mdi-white-balance-sunny' : 'mdi-weather-night'
        }}</v-icon>
      </v-btn> -->
      <router-link to="/registro"> 
      <v-btn plain>
        Registrarse
      </v-btn>
      </router-link>
      <router-link to="/login">
      <v-btn color="primary" fab small>
        
        <v-icon>
          mdi-account-circle
        </v-icon>
      </v-btn>
    </router-link>
    </v-app-bar>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
    name: "Header",
    data() {
        return {
            clipped: false,
            drawer: false,
            token2: null,
            items: [
                {
                    icon: 'mdi-folder-home-outline',
                    title: 'Inicio',
                    to: '/',
                },
                {
                    icon: 'mdi-account',
                    title: 'Información',
                    to: '/about',
                },
                {
                    icon: 'mdi-cash-usd',
                    title: 'Nosotros',
                    to: '/nosotros',
                },
            ],
        };
    },
    mounted() {
        this.validartoken()
    },
    methods: {
        validartoken(){
          const token = localStorage.getItem("user_token");
          if(token){
            this.token2 = token
            const user_token = JSON.parse(token);
            this.$store.state.usuario = user_token
            this.$router.push('/cliente');
          }
        },
        changeThemeColor() {
            if (this.$vuetify.theme.dark === true) {
                this.$vuetify.theme.dark = false;
            }
            else {
                this.$vuetify.theme.dark = true;
            }
        },
    },
    components: { RouterLink }
}
</script>

<style scoped>
.submenubtn {
  cursor: default;
}
</style>
