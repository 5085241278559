import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuesax from "vuesax";
import "vuesax/dist/vuesax.css"; //Vuesax styles
import vuetify from './plugins/vuetify'
import 'material-icons/iconfont/material-icons.css';
import { app } from "./firebase"; 


Vue.config.productionTip = false;
Vue.prototype.$app = app;
// Verifica si existe un token en el almacenamiento local
const token = localStorage.getItem('user_token');

if (token) {
  // Si hay un token, actualiza el estado de autenticación en Vuex
  store.dispatch('setToken', token);
}

// Verificamos si el token existe
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = store.getters.existeUsuario;

  if (requiresAuth && !isAuthenticated) {
    next('/login'); // Si requiere autenticación y no está autenticado, ir a la página de login
  } else if (to.path === '/login' && isAuthenticated) {
    next('/'); // Si intenta acceder a la página de login estando autenticado, redirigir a la página principal
  } else {
    next(); // Permitir la navegación
  }
});

Vue.use(Vuesax, {
  colors: {
    primary: "#80CCF5",
    success: "#1190D4",
    danger: "#B9E2F9",
    warning: "rgb(255, 130, 0)",
    dark: "#010A0E",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
