// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC_cJsWoBjCRYQzBSxgorfRAZ1mVR2tkPw",
  authDomain: "memoria-de-titulo-62c58.firebaseapp.com",
  projectId: "memoria-de-titulo-62c58",
  storageBucket: "memoria-de-titulo-62c58.appspot.com",
  messagingSenderId: "271586140900",
  appId: "1:271586140900:web:ad7a96b252b4f301d042f1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app; 