<template>
  
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import Api from "./api"
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth"; 
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components:{

  }
};
</script>
