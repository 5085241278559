import Vue from 'vue'
import Vuex from 'vuex' 


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario: null,
    eventos: [],
    actividades: [], // Añadir actividades al estado
    verGrupo: 0,
    grupoSeleccionado: null,
    fotoPerfil: null,
    nombrePerfil: "",
    eventosMiCaledario: [],
    selectedBlockIds: [],
    selectedBlockId: null,
  },
  getters: {
    existeUsuario(state) {
      return state.usuario !== null;
    }
  },
  mutations: {
    setUsuario(state, payload) {
      state.usuario = payload;
    },
    setActividades(state, payload) {
      state.actividades = payload;
    },
    agregarActividad(state, actividad) {
      state.actividades.push(actividad);
    },

    ADD_BLOCK_ID(state, blockId) {
      if (!state.selectedBlockIds.includes(blockId)) {
        state.selectedBlockIds.push(blockId);
      }
    },
    REMOVE_BLOCK_ID(state, blockId) {
      state.selectedBlockIds = state.selectedBlockIds.filter(id => id !== blockId);
    },
    setSelectedBlockId(state, blockId) {
      state.selectedBlockId = blockId;
    },
    clearSelectedBlockId(state) {
      state.selectedBlockId = null;
    },
  },
  actions: {
    setUsuario({ commit }, payload) {
      commit('setUsuario', payload);
    },
    setActividades({ commit }, payload) {
      commit('setActividades', payload);
    },
    agregarActividad({ commit }, actividad) {
      commit('agregarActividad', actividad);
    },
    setToken: ({ commit }, user) => {
      commit("setUsuario", user);
    }, 
    


    addSelectedBlock({ commit }, blockId) {
      commit('ADD_BLOCK_ID', blockId);
    },
    removeSelectedBlock({ commit }, blockId) {
      commit('REMOVE_BLOCK_ID', blockId);
    }

  },
  modules: {
  }
});