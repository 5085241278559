<template>
  <div>
    <SiteHeader/>
    <section id="home">
      <v-row no-gutters>
        <v-col cols="12">
          <SectionsHero />
          <SectionsIntro />
        </v-col>
      </v-row>
    </section>
  </div>
</template>


<script>
import SectionsHero from "../components/Secciones/Hero.vue"
import SectionsIntro from "../components/Secciones/Intro.vue"
import SectionsTestimonials from "../components/Secciones/Testimonials.vue"
import SiteHeader from "@/components/siteHeader.vue"

export default {

  components: {

    SectionsHero,
    SectionsIntro,

    SectionsTestimonials,
    SiteHeader

  }



}
</script>